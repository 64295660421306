import {Injectable, signal} from '@angular/core';
import {SessionStorageService} from '@core/services/storages/session-storage.service';
import {BehaviorSubject, distinctUntilChanged, Observable} from 'rxjs';
import {AuthService} from '@app/modules/pages/login/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CoatoCodeService {
  _coatoCode$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  _coatoName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isCoatoChange = signal(false);
  private currentUser: any;

  constructor(
    public sessionStorageService: SessionStorageService,
    private _auth: AuthService
  ) {
    this._auth.currentUser.pipe(distinctUntilChanged()).subscribe(_user => {
      this.currentUser = _user;

      // if (!this._coatoCode$.value && this.currentUser?.coato_code) {
      //   this.setCoatoCode(this.currentUser.coato_code);
      // }
    });

    if (this.getCoatoCodeValue()) {
      this.setCoatoCode(this.getCoatoCodeValue());
    }
  }

  getCoatoCode() {
    return this.sessionStorageService.get('coato_code') || this.currentUser?.coato_code;
  }

  getCoatoCodeValue() {
    return this.sessionStorageService.get('coato_code');
  }

  setCoatoCode(code: string) {
    this.sessionStorageService.set('coato_code', code);
    this._coatoCode$.next(code);
  }


  getCoatoName(): Observable<string> {
    return this._coatoName$.asObservable();
  }

  setCoatoName(name) {
    this.sessionStorageService.set('coato_name', name);
  }

  removeCoatoName() {
    this.sessionStorageService.remove('coato_name');
  }


  removeCoatoCode() {
    this.sessionStorageService.remove('coato_code');
  }

  setParentCode(code: string) {
    this.sessionStorageService.set('parentCode', code);
  }

  removeParentCode() {
    this.sessionStorageService.remove('parentCode');
  }

}
